/*
Responsive text size framework.
*/

$fs-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 1024px,
  lg: 1541px,
  xl: 1921px
);

$fs-scales: (
  xs: 0.8,
  sm: 0.9,
  md: 1,
  lg: 1.1,
  xl: 1.2
);

$fs-base: 0.80rem;
$fs-sizes: [ -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40 ];

@each $index, $breakpoint in $fs-breakpoints {
  $fs-scale: map-get($fs-scales, $index);
  @media (min-width: $breakpoint) {
    /* #{$index} screen size */
    @each $size in $fs-sizes {
      .fs__#{$size} {
        font-size: max(($fs-base + $size * $fs-base / 10) * $fs-scale, 0.1) !important;
      }
    }

    .table-column {
      font-size: max(($fs-base + 0 * $fs-base / 10) * $fs-scale, 0.1) !important;
    }
  }
}

/*
For reference:
@media (min-width: 1541px) {
  .fs__-10 {
    font-size: 0.1 !important;
  }
  .fs__-9 {
    font-size: 0.1 !important;
  }
  .fs__-8 {
    font-size: 0.165rem !important;
  }
  .fs__-7 {
    font-size: 0.2475rem !important;
  }
  .fs__-6 {
    font-size: 0.33rem !important;
  }
  .fs__-5 {
    font-size: 0.4125rem !important;
  }
  .fs__-4 {
    font-size: 0.495rem !important;
  }
  .fs__-3 {
    font-size: 0.5775rem !important;
  }
  .fs__-2 {
    font-size: 0.66rem !important;
  }
  .fs__-1 {
    font-size: 0.7425rem !important;
  }
  .fs__0 {
    font-size: 0.825rem !important;
  }
  .fs__1 {
    font-size: 0.9075rem !important;
  }
  .fs__2 {
    font-size: 0.99rem !important;
  }
  .fs__3 {
    font-size: 1.0725rem !important;
  }
  .fs__4 {
    font-size: 1.155rem !important;
  }
  .fs__5 {
    font-size: 1.2375rem !important;
  }
  .fs__6 {
    font-size: 1.32rem !important;
  }
  .fs__7 {
    font-size: 1.4025rem !important;
  }
  .fs__8 {
    font-size: 1.485rem !important;
  }
  .fs__9 {
    font-size: 1.5675rem !important;
  }
  .fs__10 {
    font-size: 1.65rem !important;
  }
  .fs__11 {
    font-size: 1.7325rem !important;
  }
  .fs__12 {
    font-size: 1.815rem !important;
  }
  .fs__13 {
    font-size: 1.8975rem !important;
  }
  .fs__14 {
    font-size: 1.98rem !important;
  }
  .fs__15 {
    font-size: 2.0625rem !important;
  }
  .fs__16 {
    font-size: 2.145rem !important;
  }
  .fs__17 {
    font-size: 2.2275rem !important;
  }
  .fs__18 {
    font-size: 2.31rem !important;
  }
  .fs__19 {
    font-size: 2.3925rem !important;
  }
  .fs__20 {
    font-size: 2.475rem !important;
  }
  .fs__21 {
    font-size: 2.5575rem !important;
  }
  .fs__22 {
    font-size: 2.64rem !important;
  }
  .fs__23 {
    font-size: 2.7225rem !important;
  }
  .fs__24 {
    font-size: 2.805rem !important;
  }
  .fs__25 {
    font-size: 2.8875rem !important;
  }
  .fs__26 {
    font-size: 2.97rem !important;
  }
  .fs__27 {
    font-size: 3.0525rem !important;
  }
  .fs__28 {
    font-size: 3.135rem !important;
  }
  .fs__29 {
    font-size: 3.2175rem !important;
  }
  .fs__30 {
    font-size: 3.3rem !important;
  }
  .fs__31 {
    font-size: 3.3825rem !important;
  }
  .fs__32 {
    font-size: 3.465rem !important;
  }
  .fs__33 {
    font-size: 3.5475rem !important;
  }
  .fs__34 {
    font-size: 3.63rem !important;
  }
  .fs__35 {
    font-size: 3.7125rem !important;
  }
  .fs__36 {
    font-size: 3.795rem !important;
  }
  .fs__37 {
    font-size: 3.8775rem !important;
  }
  .fs__38 {
    font-size: 3.96rem !important;
  }
  .fs__39 {
    font-size: 4.0425rem !important;
  }
  .fs__40 {
    font-size: 4.125rem !important;
  }
  .table-column {
    font-size: 0.825rem !important;
  }
}
*/