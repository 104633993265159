/*****************************************
Disabled rows ****************************
*****************************************/

.SecNonSelectableRow {
  opacity: 0.6;
  color: rgba(255, 255, 255, 0.75);

  input[type='checkbox'] {
    cursor: not-allowed;
    &:after {
      display: inline-block;
      content: '\00d7';
      position: relative;
      left: 2px;
      top: -3px;
      color: #555;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.table-hover tbody tr.SecNonSelectableRow:hover {
  background-color: initial;
}

.table-striped tbody tr.SecNonSelectableRow:nth-of-type(even) {
  background-color: initial;
}

.table-hover tbody tr.SecNonSelectableRow:hover {
  color: rgba(255, 255, 255, 0.75);
}

/*****************************************
Overrides  *******************************
*****************************************/
.text-11 {
  font-size: 11px;
}