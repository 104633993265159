@import 'overrides';
@import 'text';
@import 'themes';
@import 'tables';

/*******************************************
********** Scrollbars **********************
*******************************************/
.CustomScrollbar {
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #ccc;
    &:hover {
      background-color: #aaa !important;
    }
  }
}

.CustomScrollbar.CustomScrollbar-Dark {
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    &:hover {
      background-color: #aaa !important;
    }
  }
}

.CustomScrollbar.CustomScrollbar-Small {
  &::-webkit-scrollbar-track {
    border-radius: 6px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
  }
}

/*******************************************
********** Text ****************************
*******************************************/
.SingleLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
