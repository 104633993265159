.DarkTheme {
  --text-color-primary: #fff;
  --text-color-opposite: #222;
  --text-color-highlight: #2d7ce5;
  --background-color-primary: #0e1c2f;
  --border-color-primary: rgba(255, 255, 255, 0.1);
}

.LightTheme {
  --text-color-primary: #222;
  --text-color-opposite: #fff;
  --text-color-highlight: #2d7ce5;
  --background-color-primary: #fff;
  --border-color-primary: rgba(0, 0, 0, 0.1);
}

.TextColorPrimary {
  color: var(--text-color-primary) !important;
}

.TextColorOpposite {
  color: var(--text-color-opposite) !important;
}

.TextColor-SelectedTab {
  color: var(--text-color-highlight) !important;
}

.BackgroundColorPrimary {
  background-color: var(--background-color-primary);
}

.BorderColorPrimary {
  border-color: var(--border-color-primary);
}
