/* Fix color of button text changing after being clicked */
.btn-outline-danger:focus:not(:disabled):not(.disabled) {
  color: #e63757 !important;
}

.btn-outline-danger:focus:hover:not(:disabled):not(.disabled) {
  color: #fff !important;
}

.btn-outline-danger:active:not(:disabled):not(.disabled) {
  color: #fff !important;
}

.btn-outline-primary:focus:not(:disabled):not(.disabled) {
  color: #2c7be5 !important;
}

.btn-outline-primary:focus:hover:not(:disabled):not(.disabled) {
  color: #fff !important;
}

.btn-outline-primary:active:not(:disabled):not(.disabled) {
  color: #fff !important;
}

/* Fix color of tab button text changing after being clicked
on Template Library and Landing pages Library pages.
*/
.ButtonGroup__Button {
  &.btn-outline-primary:focus:not(:disabled):not(.disabled):not(:hover).active {
    color: #0e1c2f !important;
  }
}

.LightTheme .ButtonGroup__Button {
  &.btn-outline-primary:focus:not(:disabled):not(.disabled):not(:hover).active {
    color: #fff !important;
  }
}