.ErrorModal__Container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.ErrorModal__Inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 480px;
  padding: 24px 24px 8px;
  border-radius: 8px;

  .DarkTheme & {
    background-color: #132238;
  }

  .LightTheme & {
    background-color: #fff;
  }
}

.ErrorModal__Title {
  font-size: 0.9rem;
}

.ErrorModal__Footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;

  button {
    opacity: 0.8;
  }
}
